@import '../../../../variables.css';

.content {
    width: 100%;
    margin: 0 auto;
    padding: 68px 0;
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
}

.container {
    padding: 80px 0;
}

.mainTitle {
    margin-bottom: 32px;
    font-size: var(--ark-36-font-size);
    line-height: var(--ark-48-line-height);
}

.columnsList {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 24px;
}

@media screen and (max-width: 1266px) {
    .container {
        padding: 32px 0;
    }

}

@media screen and (max-width: 830px) {
    .columnsList {
        flex-direction: column;
        align-items: center;
    }

    .container {
        padding: 0 16px;
    }

    .content {
        padding: 20px 0;
    }
}

@media screen and (max-width: 600px) {
    .container {
        width: 100%;
    }
}