@import '../../../../variables.css';

.box {
    width: 400px;
    height: 270px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 3px solid currentColor;
    border-radius: 16px;
    padding: 0 58px;
    margin-bottom: 16px;
}

.box.topPadding {
    /* --saving-label-height: 41px; */
    padding-top: 41px;
}

.savingLabel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    /* --saving-label-height: 41px; */
    height: 41px;
    border-radius: 11px 11px 0 0;
    font-weight: var(--ark-font-weight-heavy);
    font-size: var(--ark-20-font-size);
    line-height: var(--ark-25-line-height);
    color: var(--ark-primary-black-figma);
    padding: 8px 0;
}

.title {
    font-weight: var(--ark-font-weight-bold);
    font-size: var(--ark-28-font-size);
    line-height: var(--ark-39-line-height);
}

.shortTitle {
    display: none;
    font-weight: var(--ark-font-weight-bold);
    font-size: var(--ark-26-font-size);
    line-height: var(--ark-36-line-height);
}

.price {
    color: var(--ark-primary-white-figma);
    margin-top: 8px;
    font-weight: var(--ark-font-weight-bold);
    font-size: var(--ark-18-font-size);
    line-height: var(--ark-25-line-height);
}

.bestDealLabel {
    white-space: pre-line;
    color: var(--ark-primary-white-figma);
    font-size: var(--ark-16-font-size);
    line-height: var(--ark-22-line-height);
    margin-top: 16px;
}

/* MainModule panels for mobile */
.panel.mobileAltVersion {
    display: block;
}

.panel.mobileAltVersion .box {
    padding: 16px;
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 16px;
    align-content: center;
}

.panel.mobileAltVersion .title, .panel.mobileAltVersion .shortTitle {
    text-align: left;
    font-size: var(--ark-20-font-size);
    line-height: var(--ark-28-line-height);
}

.panel.mobileAltVersion .price {
    text-align: left;
    font-size: var(--ark-16-font-size);
    line-height: var(--ark-22-line-height);
}

@media screen and (max-width: 1266px) {
    .panel {
        width: 224px;
    }

    .box {
        width: 100%;
        height: 215px;
        padding: 20px 16px;
    }

    .panel:last-child .box {
        justify-content: end;
    }

    .box.topPadding {
        /* --saving-label-height: 31px; */
        padding-top: 31px;
    }

    .savingLabel {
        font-size: var(--ark-16-font-size);
        line-height: var(--ark-22-line-height);
        /* --saving-label-height: 31px; */
        height: 31px;
        padding: 4px 0;
    }

    .title {
        display: none;
    }

    .shortTitle {
        display: initial;
    }

    .price {
        font-size: var(--ark-16-font-size);
        line-height: var(--ark-22-line-height);
        word-break: break-all;
        white-space: pre-wrap;
    }
}

@media screen and (max-width: 830px) {
    .panel {
        width: 100%;
        max-width: 287px;
    }

    .panel.mobileAltVersion {
        max-width: unset;
        min-width: 287px;
        width: 90%;
        margin: auto;
    }

    .box {
        height: min-content;
        min-height: 163px;
    }

    .panel.mobileAltVersion .box {
        min-height: unset;
    }

    .box.topPadding {
        padding-top: 51px;
    }

    .shortTitle {
        font-size: var(--ark-24-font-size);
        line-height: var(--ark-34-line-height);
    }
}

@media screen and (max-width: 600px) {
    .panel {
        max-width: none;
    }

    .panel.mobileAltVersion {
        width: 100%;
    }

    .panel.mobileAltVersion .box {
        margin: 0;
    }
}

/* specific edge case for the two columns (instead of 3) design, they are quite different */
@media screen and (min-width: 830px) and (max-width: 1266px) {

    .box.twoColumnsDesign.topPadding {
        /* --saving-label-height: 24px; */
        padding-top: 24px;
    }

    .twoColumnsDesign .savingLabel {
        font-size: var(--ark-11-font-size);
        line-height: var(--ark-15-line-height);
        /* --saving-label-height: 24px; */
        height: 24px;
        padding: 2px 0 4px 0;
    }

    .twoColumnsDesign .shortTitle {
        font-size: var(--ark-22-font-size);
        line-height: var(--ark-31-line-height);
    }

    .twoColumnsDesign .price {
        margin-top: 4.6px;
    }

    .twoColumnsDesign .bestDealLabel {
        font-size: var(--ark-12-font-size);
        line-height: var(--ark-20-line-height);
        margin-top: 9px;
    }
}